const canvas: HTMLCanvasElement = document.querySelector(
  ".services-container-wave"
);
const dev = document.querySelector(".services-container-dev");

const ctx: CanvasRenderingContext2D = canvas.getContext("2d");
canvas.width = 165;
canvas.height = 140;
let x = 0;
let y = 60;
let animationRunningFirst = true;
let direction = true;
let nextAnimationProgress = 0;

ctx.lineWidth = 28;
ctx.moveTo(0, 60);

let animationProgress = 0;

let strokeColor = "#EEEEEE";

function animate() {
  ctx.clearRect(0, 0, canvas.width, canvas.height);

  ctx.beginPath();
  ctx.moveTo(-5, 30);
  for (let i = 0; i < 170; i++) {
    x = i;
    y = 30 + 10 * Math.sin(i * 0.05) * (1 - animationProgress / Math.PI);
    ctx.lineTo(x, y);
  }
  ctx.setLineDash([
    32,
    nextAnimationProgress,
    62,
    nextAnimationProgress / 2,
    200,
    0,
  ]);
  ctx.strokeStyle = strokeColor;
  ctx.stroke();

  ctx.beginPath();
  ctx.moveTo(-5, 78);
  for (let i = 0; i < 170; i++) {
    x = i;
    y = 78 + 10 * Math.sin(i * 0.05) * (1 - animationProgress / Math.PI);
    ctx.lineTo(x, y);
  }
  ctx.setLineDash([
    32,
    nextAnimationProgress,
    100,
    nextAnimationProgress * 2.5,
    200,
    0,
  ]);
  ctx.strokeStyle = strokeColor;
  ctx.stroke();

  ctx.beginPath();
  ctx.moveTo(-5, 126);
  for (let i = 0; i < 170; i++) {
    x = i;
    y = 126 + 10 * Math.sin(i * 0.05) * (1 - animationProgress / Math.PI);
    ctx.lineTo(x, y);
  }
  ctx.strokeStyle = strokeColor;
  ctx.setLineDash([32, nextAnimationProgress, 200, 0]);
  ctx.stroke();

  if (animationRunningFirst) {
    animationRunningFirst = false;
    return;
  }

  if (direction) {
    if (animationProgress === Math.PI) {
      nextAnimationProgress = Math.min(20, nextAnimationProgress + 4);
      if (nextAnimationProgress === 20) {
        direction = false;
        return;
      }
    }
    animationProgress = Math.min(Math.PI, animationProgress + 0.3);
    strokeColor = getIntermediateColor(
      ctx.strokeStyle,
      "#00155C",
      animationProgress,
      Math.PI
    );
    requestAnimationFrame(animate);
  } else {
    if (nextAnimationProgress === 0) {
      if (animationProgress === 0) {
        direction = true;
        return;
      }
      animationProgress = Math.max(0, animationProgress - 0.3);
    }
    nextAnimationProgress = Math.max(0, nextAnimationProgress - 4);
    strokeColor = getIntermediateColor(
      ctx.strokeStyle,
      "#EEEEEE",
      animationProgress,
      Math.PI
    );
    requestAnimationFrame(animate);
  }
}

// Function to get the intermediate color between two colors
function getIntermediateColor(
  startColor: string,
  endColor: string,
  progress: number,
  duration: number
) {
  var startRGB = hexToRgb(startColor);
  var endRGB = hexToRgb(endColor);
  var diffRGB = {
    r: endRGB.r - startRGB.r,
    g: endRGB.g - startRGB.g,
    b: endRGB.b - startRGB.b,
  };
  var intermediateRGB = {
    r: Math.round(startRGB.r + (diffRGB.r * progress) / duration),
    g: Math.round(startRGB.g + (diffRGB.g * progress) / duration),
    b: Math.round(startRGB.b + (diffRGB.b * progress) / duration),
  };
  return rgbToHex(intermediateRGB);
}

// Function to convert hex color to RGB
function hexToRgb(hex: string) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

// Function to convert RGB color to hex
function rgbToHex(rgb: { r: number; g: number; b: number }) {
  return (
    "#" +
    ((1 << 24) + (rgb.r << 16) + (rgb.g << 8) + rgb.b).toString(16).slice(1)
  );
}

animate();
if (!("ontouchstart" in document.documentElement)) {
  dev.addEventListener("mouseenter", () => {
    animate();
  });
  dev.addEventListener("mouseleave", () => {
    animate();
  });
}
