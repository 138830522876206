import Swiper, { Navigation } from "swiper";

const descriptionSwiper = new Swiper(".description-container-photo", {
  loop: true,
  modules: [Navigation],
  navigation: {
    nextEl: ".description-wrapper .arrow-buttons-right",
    prevEl: ".description-wrapper .arrow-buttons-left",
  },
});
