import Swiper, { Navigation, Pagination } from "swiper";

const ourRatingsSwiper = new Swiper(".our-ratings-carousel", {
  speed: 700,
  loop: true,
  slidesPerView: 1,
  mousewheel: {
    releaseOnEdges: true,
  },
  centeredSlides: true,
  modules: [Navigation, Pagination],
  navigation: {
    nextEl: ".our-ratings .arrow-buttons-right",
    prevEl: ".our-ratings .arrow-buttons-left",
  },
  breakpoints: {
    430: {
      slidesPerView: "auto",
    },
  },
});
