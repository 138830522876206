import Accordion from "accordion-js";
import "accordion-js/dist/accordion.min.css";

if (document.querySelector(".accordion-container")) {
  new Accordion(".accordion-container");
}
if (document.querySelector(".accordion-principles")) {
  new Accordion(".accordion-principles");
}
if (document.querySelector(".accordion-faq")) {
  new Accordion(".accordion-faq");
}
