import Swiper, { Grid, Navigation, Pagination } from "swiper";

const writeAboutUsSwiper = new Swiper(".write-carousel-container", {
  speed: 700,
  loop: true,
  freeMode: true,
  mousewheel: {
    releaseOnEdges: true,
  },
  modules: [Navigation, Pagination, Grid],
  navigation: {
    nextEl: ".write-header-control .arrow-buttons-right",
    prevEl: ".write-header-control .arrow-buttons-left",
  },
  slidesPerView: 1,
  grid: {
    rows: 2,
  },
  spaceBetween: 20,
  breakpoints: {
    430: {
      centeredSlides: true,
      slidesPerView: "auto",
      grid: {
        rows: 1,
      },
    },
  },
});
