import Swiper, { Navigation } from "swiper";

const weWriteSwiper = new Swiper(".weWrite-carousel", {
  speed: 700,
  loop: true,
  slidesPerView: 1,
  mousewheel: {
    releaseOnEdges: true,
  },
  spaceBetween: 40,
  modules: [Navigation],
  navigation: {
    nextEl: ".weWrite .arrow-buttons-right",
    prevEl: ".weWrite .arrow-buttons-left",
  },
});
