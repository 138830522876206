import Swiper, { Navigation, Pagination } from "swiper";

const feedbackSwiper = new Swiper(".feedback-carousel", {
  speed: 700,
  loop: true,
  slidesPerView: "auto",
  freeMode: true,
  mousewheel: {
    releaseOnEdges: true,
  },
  centeredSlides: true,
  spaceBetween: 40,
  modules: [Navigation, Pagination],
  navigation: {
    nextEl: ".feedback-header .arrow-buttons-right",
    prevEl: ".feedback-header .arrow-buttons-left",
  },
});
