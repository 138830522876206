import Swiper, { Navigation, Pagination } from "swiper";

const portfolioSwiper = new Swiper(".portfolio-carousel", {
  speed: 700,
  loop: true,
  slidesPerView: "auto",
  freeMode: true,
  mousewheel: {
    releaseOnEdges: true,
  },
  centeredSlides: true,
  modules: [Navigation, Pagination],
  breakpoints: {
    1240: {
      spaceBetween: 40,
    },
  },
  navigation: {
    nextEl: ".portfolio-header .arrow-buttons-right",
    prevEl: ".portfolio-header .arrow-buttons-left",
  },
});
