import Swiper, { Navigation } from "swiper";

const items = document.querySelectorAll(".products-container-items h3");
items[0].addEventListener("click", (e) => OpenInformation(e.target, "easyDev"));
items[2].addEventListener("click", (e) =>
  OpenInformation(e.target, "helpmate")
);

function OpenInformation(e, productName: string) {
  let tabContents: HTMLCollectionOf<Element>;
  let tabLinks: HTMLCollectionOf<Element>;

  tabContents = document.getElementsByClassName("info-content");
  for (let i = 0; i < tabContents.length; i++) {
    (tabContents[i] as HTMLElement).style.display = "none";
  }

  tabLinks = document.getElementsByClassName("item-link");
  for (let i = 0; i < tabLinks.length; i++) {
    tabLinks[i].className = tabLinks[i].className.replace(" active", "");
  }

  const element = document.getElementById(productName);
  if (element) element.style.display = "block";

  e.classList.add("active");

  let tabIndicator = document.getElementsByClassName("item-indicator")[0];

  if (productName === "easyDev") {
    document.getElementsByClassName("products")[0].classList.remove("helpmate");
    document.getElementsByClassName("products")[0].classList.add("easyDev");
    tabIndicator.classList.remove("item-indicator--left");
  } else {
    document.getElementsByClassName("products")[0].classList.remove("easyDev");
    document.getElementsByClassName("products")[0].classList.add("helpmate");
    tabIndicator.classList.add("item-indicator--left");
  }
}

function changeActiveSlide(productName: string) {
  let tabLinks: HTMLCollectionOf<Element>;

  tabLinks = document.getElementsByClassName("item-link");
  for (let i = 0; i < tabLinks.length; i++) {
    tabLinks[i].classList.toggle("active");
  }

  if (productName === "easyDev") {
    document.getElementsByClassName("products")[0].classList.remove("helpmate");
    document.getElementsByClassName("products")[0].classList.add("easyDev");
  } else {
    document.getElementsByClassName("products")[0].classList.remove("easyDev");
    document.getElementsByClassName("products")[0].classList.add("helpmate");
  }
}

let init: boolean = false;
let swiper: Swiper;
const initSwiper = () => {
  if (window.innerWidth <= 430) {
    if (!init) {
      init = true;
      swiper = new Swiper(".products-container-info", {
        speed: 500,
        loop: true,
        freeMode: true,
        mousewheel: {
          releaseOnEdges: true,
        },
        centeredSlides: true,
        modules: [Navigation],
        navigation: {
          nextEl: ".products-header .arrow-buttons-right",
          prevEl: ".products-header .arrow-buttons-left",
        },
        on: {
          slideChange(swiper) {
            if (swiper.realIndex === 0) {
              changeActiveSlide("easyDev");
            } else {
              changeActiveSlide("helpmate");
            }
          },
        },
      });
    }
  } else if (init) {
    swiper.destroy();
    init = false;
  }
};

initSwiper();
window.addEventListener("resize", initSwiper);
